.historyContainer{
    display:flex;
    flex-direction: column;
    background-color: hwb(0 0% 100%);
    width: 100%;
    align-items: center;
}


.historyBar{
    width: 1000px;
    height: 60px;
    margin: 10px;
    padding: 10px;
    padding-top: 40px;
    background-color: #517298;
    font-size: 30px;
    color:aliceblue
}
button{
    width: 150px;
    z-index: 10;
    margin-top:auto;
}