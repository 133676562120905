.header {
    font-size: 2.5rem;
    padding: 1rem;
    background-color: #4F58DF;
    color: #FFFFFF;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center;
  }
  
  .container {
    display: flex;
    width: 90%;
    max-width: 1200px;
    justify-content: space-between;
    margin: auto;
  }