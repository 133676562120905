.boardSelectContainer{
    margin: auto;
    width: 60%;
    margin-top: 5%
}
.boardSelect{

    width: 1000px;
    height: 50px;

}
button{
    width: 300px;
    height: 50px;
    margin-left: 30%;
    margin-top: 10%;
}

