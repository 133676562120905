.row {
    display: flex;
}
  
.box {
    background-color: #d9811a;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-width: 1px;
}
  
.black{
    background-color: black;
}
.white{
    background-color: white;
}

.circle {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 40px;
    height: 40px;
    border-radius: 50%; 
}

.message {
    margin-left: 20%;
    margin-top: 10%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

button{
    width: 300px;
    height: 50px;
    margin-left: 150px;
    margin-top: 10%;
}

.turn{
    color:rgb(255, 30, 0);
    margin-left: 8px;
    margin-bottom: 5px;
    font-size: 25px;
}